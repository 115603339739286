import axios from 'axios';

const userAxios = axios.create({
  headers: {
    common: {},
  },
});
let store = null;
export default {
  data() {
    return {
      request: {
        user: userAxios,
      },
    };
  },

  created() {
    // Admin base URL 설정.
    this.request.user.defaults.baseURL = `https://master-dev.launchpack.co.kr:1111/user/${this.$store.getters.user.user_id}`;

    // 인증 설정.
    if (this.$store.getters.user.token !== '') {
      const auth = 'Token ' + this.$store.getters.user.token;
      this.setHeader(this.request.user, 'Authorization', auth);
    }
    store = this.$store;
    this.request.user.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          store.commit('logOut');
          this.$router.replace('/');
          this.toast('로그인 된 인증정보가 맞지 않아서 로그아웃되었습니다.');
        }
        return Promise.reject(error);
      }
    );
  },

  methods: {
    setHeader(request, key, value) {
      request.defaults.headers.common[key] = value;
    },
    // 로그아웃
    async logout() {
      await this.request.user
        .post(`https://master-dev.launchpack.co.kr:1111/auth/user/logout`)
        .then(() => {
          this.$store.commit('logOut');
          delete this.request.user.defaults.headers.common['Authorization'];
          this.$router.replace('home');
        })
        .catch((err) => {
          if (err.response.data.detail === undefined) this.toast(err.response.data.message);
          else this.toast(err.response.data.detail);
        });
    },
  },
};
